import * as React from "react"
import { Link } from "gatsby"
import '../styles/404.css'

// markup
const NotFoundPage = () => {
  return (
    <main className="body">
      <title>Not found</title>
      <div id="notfound">
        <div class="notfound">
          <div class="notfound-404">
            <h1>Oops!</h1>
          </div>
          <h2 style={{paddingTop: '25px'}}>404 - Page not found</h2>
          <p>The page you are looking for might have been removed or had its name changed or is temporarily unavailable.</p>
          <Link to="/">Go To Homepage</Link>
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage